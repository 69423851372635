.Company {
  min-height: 100vh;
  background-color: #f5f9fc;

  /* background-color: #f7f6f5; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Company .content {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;
}

.Company .company-cards-container {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Company .company-cards-container .company-filter-card {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  min-height: 40vh;
  width: 90%;
  margin: 20px 0px 30px 0px;
  border-radius: 8px;
  padding: 30px 50px;
}

.Company .company-cards-container .company-filter-card .company-logo {
  border: 1px solid #d5d5d5;
  padding: 10px;
  border-radius: 8px;
  background: #f6f6f6;
}

.Company .company-cards-container .company-filter-card .company-info-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
}

.Company
  .company-cards-container
  .company-filter-card
  .company-info-container
  .side-container {
  margin-left: auto;
  margin-right: 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  width: 30%;
}

.Company
  .company-cards-container
  .company-filter-card
  .company-info-container
  .side-container
  .funding-detail-container {
  margin-left: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #4659ff;
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  min-height: 100px;
  padding: 20px;
}

.Company
  .company-cards-container
  .company-filter-card
  .company-info-container
  .company-info-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px 0px 0px 30px;
}

.Company
  .company-cards-container
  .company-filter-card
  .company-info-container
  .company-info-text
  .text-1 {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #151417;
}

.Company
  .company-cards-container
  .company-filter-card
  .company-info-container
  .company-info-text
  .text-2 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #5c5c5c;
}

.text-1 {
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #151417;
}

.text-2 {
  margin-bottom: 2px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #5c5c5c;
}

.Company .text-3 {
  width: 60%;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  color: #5c5c5c;
}

.Company .company-cards-container .company-filter-card .section-text {
  margin: 30px 0px 10px 0px;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  color: #151417;
}

.Company .company-cards-container .company-filter-card .link {
  margin: 5px 5px 10px 0px;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #4659ff;
}

.Company .company-cards-container .company-filter-card .link:hover {
  color: #4324b5;
}

.Company .company-cards-container .company-filter-card .actions {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
