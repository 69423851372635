.wrapper {
    padding: 20px 30px;
}

.pageButtons,
.pageButtonActive,
.pageButtonDisabled {
    margin: 0.3rem;
    padding: 0px 10px;
    min-height: 35px;
    min-width: 35px;
    color: #151417;
    font-size: 13.5px;
    background-color: transparent;
    border: 1px solid #d5d5d5;
    cursor: pointer;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    border-radius: 0.3rem;
}

.pageButtons:hover {
    border: 1px solid #3c67ff;
    background-color: #f6f6f6;
}

.pageButtonDisabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pageButtonActive {
    font-weight: bold;
    border: 1px solid #3c67ff;
    color: #3c67ff;
}