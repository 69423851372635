* {
  margin: 0;
  padding: 0;
}

.Topnav {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  height: 65px;
  /* border-bottom: 1px solid #e2e2e2;*/
  border-bottom: 1px solid #ececec;
  background-color: #fff;
}

.Topnav .leadzilla-logo {
  cursor: pointer;
  height: 50px;
  margin: 0px 10px 0px 20px;
}

.Topnav .nav-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
}

.Topnav .nav-links .nav-link {
  margin: 0px 5px;
  padding: 8px 10px;
  text-align: center;
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 600;
  background-color: transparent;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.Topnav .nav-links .nav-link:hover {
  background-color: #e2e8ff;
}

.Topnav .account-info {
  margin: 0px 0px 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Topnav .account-info .profile-pic {
  cursor: pointer;
  margin: 0px 20px 0px 10px;
}

.Topnav .account-info .profile-pic img {
  height: 45px;
  border-radius: 50px;
  /* border: 2px solid #b19cff; */
}

.Topnav .account-info button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  margin: 0px 5px;
  margin-right: 20px;
  padding: 8px 10px;
  text-align: center;
  color: #4659ff;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.Topnav .account-info button:hover {
  background-color: #e2e8ff;
  color: #3c67ff;
}

.Topnav .account-info button .heroicons {
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  background-color: transparent;
  margin-right: 5px;
}

/* .Topnav .account-info .credits {
  margin: 0px 20px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.Topnav .account-info .credits h1 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #8b8b8b;
}

.Topnav .account-info .credits p {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 15px;
  color: #8b8b8b;
  font-weight: 500;
} */

.popover-link-containers {
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 5px 0px;
  border-radius: 5px;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}
.popover-link-containers:hover {
  background-color: #f6f6f6;
}

.popover-text, .popover-product-text {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0px;
  color: #222222;
}

.popover-product-text {
  font-size: 13px;
}

.popover-link-containers .link-icons {
  height: 18px;
  margin: 0px 0px 0px 15px;
}

.creditsSection {
  display: flex;
  flex-direction: column;
}

.creditCountContainer {
  display: flex;
  align-items: center;
  justify-content: right;
}

.creditCount {
  color: #8b8b8b;
  font-size: 18px;
  margin: 0px;
  text-align: right;
  font-weight: 600;
}

.creditType {
  color: #8b8b8b;
  font-size: 15px;
  margin: 0px;
  text-align: right;
  margin-left: 5px;
}