.container {
    min-height: 100vh;
    background-color: #f5f9fc;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.contentWrapper {
    margin-top: 65px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.sidebar {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
    min-height: 80vh;
    width: 300px;
    margin: 20px 10px 30px 30px;
    border-radius: 8px;
    padding: 30px 0px 30px 0px;
}

.content {
    border: 1px solid red;
    background-color: #fff;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
    min-height: 80vh;
    width: 70%;
    margin: 20px 30px 30px 10px;
    border-radius: 8px;
    padding: 30px 0px;
}

.optionsLabelContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.optionsLabelContainer:hover {
    background-color: #fafafa;
}

.labelIcon {
    height: 18px;
    margin: 0px 0px 0px 15px;
}

.labelType {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #151417;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
}

.labelType:hover {
    color: #4659ff;
}

.productHeader {
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px;
    margin-bottom: 20px;
}

.transactionPageWrapper {
    padding: 40px;
    display: flex;
    gap : 20px;
}

.primaryButton {
    all: unset;
    cursor: pointer;
    padding: 0px 10px;
    border-radius: 0.3rem;
    display: grid;
    place-items: center;
    min-height: 35px;
    color: #fff;
    font-size: 13.5px;
    font-weight: 700;
    border: 1px solid #5537c2;
    background-color: #4659ff;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
}

.primaryButton:hover {
    background-color: #192de1;
    color: #fff;
}