.Track {
  min-height: 100vh;
  background-color: #f5f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Track .bottom-nav {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 600;
  width: 100vw;
  height: 48px;
  margin-top: 65px;
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
}

.Track .cta-container {
  margin: 6px 5.5vw 0px auto;
}

.add-list-button {
  margin: 0px 0px 0px 8px;
}

.Track .content {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;
}

.Track .track-cards-container {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.Track .track-cards-container .track-filter-card {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  min-height: 40vh;
  width: 90%;
  margin: 20px 0px 30px 0px;
  border-radius: 8px;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
