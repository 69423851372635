.Onboarding {
  min-height: 100vh;
  background-color: #f5f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Onboarding .content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent;
}

.Onboarding .content .onboarding-side {
  min-height: 100vh;
  width: calc(100% - 450px);
  background-color: #f5f9fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Onboarding .content .onboarding-side .onboarding-container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  margin: 20px 10px 30px 30px;
  border-radius: 8px;
  padding: 30px 30px 30px 30px;
}

.Onboarding .content .onboarding-side .text-container {
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  margin: 0px 10px 0px 30px;
  border-radius: 8px;
  padding: 0px 30px 0px 30px;
  font-size: 15px;
}

.Onboarding .content .onboarding-side .onboarding-container .step-description {
  margin: 20px 0px 20px 0px;
}

.Onboarding .content .card {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  min-height: 80vh;
  width: 300px;
  margin: 20px 10px 30px 30px;
  border-radius: 8px;
  padding: 30px 0px 30px 0px;
}

.Onboarding .content .banner-side {
  min-height: 100vh;
  width: 460px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  background-color: #4659ff;
}
