.Enrich {
  min-height: 100vh;
  background-color: #f6f6f6;
  background-color: #f5f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Enrich .content {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  background-color: transparent;
}
.Enrich .content .header {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.Enrich .content .header .headerText {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Enrich .content .header h1 {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: #151417;
}

.Enrich .content .header h3 {
  text-align: left;
  width: 80%;
  font-size: 15px;
  font-weight: 500;
}

.Enrich .content .enrichTableCard {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  width: 100%;
  margin: 20px 0px 30px 0px;
  border-radius: 8px;
  padding: 30px 0px;
}

.toggleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}

.toggleContainerText {
  padding: 0;
  margin: 0;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}