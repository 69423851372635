@import "~antd/dist/antd.less";

* {
  font-family: "Montserrat", sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.antd-layout-header .credits {
  margin-left: auto;
  margin-right: 40px;
  display: flex;
}

.antd-layout-header .credits h1 {
  font-size: 17px;
  color: black;
  margin-top: 0px;
  margin-right: 5px;
}

.antd-layout-header .credits p {
  font-size: 14px;
  color: black;
  margin-top: 0px;
}

.antd-menu-item {
  font-size: 15px;
  font-weight: 600;
}

.sidenav-logo {
  /* height: 45px; */
  width: 90%;
  margin-left: 5px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.antd-card {
  border-radius: 5px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
}

.primary-button {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  min-height: 35px;
  padding: 0px 10px;
  color: #fff;
  font-size: 13.5px;
  font-weight: 700;
  border: 1px solid #5537c2;
  background-color: #4659ff;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.primary-button:hover {
  background-color: #192de1;
}

.secondary-button-inactive {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0px 10px;
  min-height: 35px;
  color: #151417;
  font-size: 13.5px;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.secondary-button-inactive:hover {
  background-color: #f6f6f6;
}

.secondary-button-active {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0px 10px;
  min-height: 35px;
  color: #151417;
  font-size: 13.5px;
  font-weight: 600;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.secondary-button-active:hover {
  border: 1px solid #3c67ff;
  background-color: #e2e8ff;
  color: #3c67ff;
  cursor: pointer;
}

input {
  height: 50px;
  width: 280px;
  padding: 1rem;
  border: 2px solid #4659ff;
  font-weight: 500;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.modal-select {
  height: 50px;
  width: 280px;
  padding-top: 5px;
  border: 2px solid #4659ff;
  font-weight: 500;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.ant-modal-content {
  border-radius: 15px;
  /* background: red; */
}

.emailHeader {
  text-align: center;
}

.emailWrapper {
  max-height: 350px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.emailBody {
  overflow: auto;
  white-space: pre-line;
  margin-top: 10px;
  width: 100%;
  height: 300px;
  padding: 10px;
  border-radius: 6px;
  resize: vertical;
}

.mailModalButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  gap: 20px;
}
@primary-color: #4659ff;@border-radius-base: 4px;@btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0);@btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0);@btn-font-weight: 600;@height-base: 35px;