.Search {
  min-height: 100vh;
  background-color: #f5f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Search .bottom-nav {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: 600;
  width: 100vw;
  height: 48px;
  margin-top: 65px;
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
}

.Search .content {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: transparent;
}

.Search .search-cards-container {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.Search .search-cards-container .search-filter-card {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  min-height: 80vh;
  width: 300px;
  margin: 20px 10px 30px 30px;
  border-radius: 8px;
  padding: 30px 0px 30px 0px;
}

.Search
  .search-cards-container
  .search-filter-card
  .filter-header-cta-container {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.Search .search-cards-container .search-filter-card .filter-heading {
  margin: 0px 0px 10px 22px;
  font-size: 11px;
  line-height: 14px;
  font-weight: 700;
  text-align: left;
  color: #8b8b8b;
}

.Search .search-cards-container .search-filter-card .search-filter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  background-color: transparent;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  cursor: pointer;
}

.Search
  .search-cards-container
  .search-filter-card
  .search-filter-container:hover {
  background-color: #fafafa;
}

.Search
  .search-cards-container
  .search-filter-card
  .search-filter-container
  .filter-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Search
  .search-cards-container
  .search-filter-card
  .search-filter-container
  .filter-type {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #151417;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.Search
  .search-cards-container
  .search-filter-card
  .search-filter-container:hover
  .filter-type {
  color: #4659ff;
}

.Search
  .search-cards-container
  .search-filter-card
  .search-filter-container
  .filter-icons {
  height: 18px;
  margin: 0px 0px 0px 15px;
}

.Search .search-cards-container .search-result-card {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  min-height: 80vh;
  width: 70%;
  margin: 20px 30px 30px 10px;
  border-radius: 8px;
  padding: 30px 0px;
}

.Search .search-cards-container .search-result-card-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0px 20px 0px;
  padding: 0px 30px;
}

.Search .available-contact-count {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  color: #151417;
  margin-right: auto;
}

.Search .contact-name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #151417;
}

.Search .contact-role {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  text-align: left;
  color: #151417;
}

.Search .contact-department {
  margin: 0px;
  font-size: 12px;
  line-height: 13px;
  font-weight: 500;
  text-align: left;
  color: #151417;
}

.Search .social-account-link-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Search .social-account-link-container .social-icons {
  margin: 5px 10px 0px 0px;
  font-size: 16px;
}

.Search .contact-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Search .contact-email {
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 13px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  color: #151417;
}

.Search .contact-container .contact-type {
  margin: 5px 5px 0px 0px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  color: #8b8b8b;
  background-color: #f6f6f6;
  padding: 5px;
  border-radius: 4px;
  width: 70px;
}

.Search .contact-container .contact-data {
  margin: 5px 5px 0px 5px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  color: #8b8b8b;
  width: 130px;
}

.Search .company-name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #151417;
}

.Search .company-name:hover {
  font-weight: 700;
  text-decoration: underline;
}

.Search .company-address {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Search .company-details {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  text-align: left;
  color: #8b8b8b;
}

.Search .company-url {
  margin-top: 2px;
  font-size: 12px;
}

.Search .primary-button {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  height: 35px;
  padding: 0px 10px;
  color: #fff;
  font-size: 13.5px;
  font-weight: 700;
  background-color: #4659ff;
  border: 1px solid #5537c2;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.Search .primary-button:hover {
  background-color: #192de1;
}

.Search .secondary-button-inactive {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0px 10px;
  height: 35px;
  color: #151417;
  font-size: 13.5px;
  font-weight: 600;
  background-color: transparent;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.Search .secondary-button-inactive:hover {
  background-color: #f6f6f6;
}

.Search .secondary-button-active {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 0px 10px;
  height: 35px;
  color: #151417;
  font-size: 13.5px;
  font-weight: 600;
  background-color: #f6f6f6;
  border: 1px solid #d5d5d5;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.Search .secondary-button-active:hover {
  border: 1px solid #4659ff;
  background-color: #e2e8ff;
  color: #4659ff;
}

.Search .export-csv-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 13.5px;
  padding: 0px 10px;
  background-color: #f6f6f6;
  color: #151417;
  cursor: pointer;
  margin: 0 0.5rem 0 0.5rem;
  padding: 0 0.5rem;
  border-radius: 4px;
  border: 1px solid #d5d5d5;
  font-weight: 600;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
}

.Search .export-csv-button:hover {
  border: 1px solid #4659ff;
  background-color: #e2e8ff;
  color: #4659ff;
  cursor: pointer;
}
