.wrapper {
    min-height: 100vh;
    background-color: #f6f6f6;
    background-color: #f5f9fc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.wrapper_content {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    background-color: transparent;
    padding-top: 50px;
}

.button_container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.contact_table {
    background-color: #fff;
    border: 1px solid #e2e2e2;
    box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
    width: 100%;
    margin: 0px 0px 30px 0px;
    border-radius: 8px;
    padding: 30px 0px;
}

.data_wrapper {
    display: flex;
}

.number_key {
    width: 80px;
    height: fit-content;
    margin-right: 5px;
    border-radius: 3px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    padding: 2px 5px;
}

.number_value {
    display: flex;
}

.copyable_text {
    margin-left: 5px;
    color: #467cd8;
}

