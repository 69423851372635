.wrapper {
    background-color: #4659ff;
    color: #e2e2e2;
    position: fixed;
    bottom: 0px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    padding: 5px;
}

.link_text {
    color: #e2e2e2;
    text-decoration: none;
    font-weight: bold;
    margin: 0px 5px;
}