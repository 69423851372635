@import url("antd/dist/antd.less");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-menu-item-selected {
  background-color: #6f4cef !important;
}

.site-layout {
  background-color: #f5f9fc;
}

.antd-layout-header {
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@primary-color: #4659ff;@border-radius-base: 4px;@btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0);@btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0);@btn-font-weight: 600;@height-base: 35px;