.Integration {
  min-height: 100vh;
  background-color: #f6f6f6;
  background-color: #f5f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Integration .content {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  background-color: transparent;
}
.Integration .content .header {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
}

.Integration .content .header h1 {
  font-size: 22px;
  font-weight: 700;
  text-align: left;
  color: #151417;
}

.Integration .content .header h3 {
  text-align: left;
  width: 50%;
  font-size: 15px;
  font-weight: 500;
}

.Integration .content .integration-card-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.Integration .integration-card-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.IntegrationCard .card {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
  height: 200px;
  width: 300px;
  border-radius: 8px;
  margin: 10px;
}

.IntegrationCard .integration-card-name h1 {
  font-size: 17px;
  margin: 4px 0px 0px 10px;
  font-weight: 600;
  text-align: left;
  color: #151417;
}
.IntegrationCard p {
  font-size: 14px;
  margin: 0px 0px 20px 0px;
  font-weight: 500;
  text-align: left;
  color: #737274;
}
