.Login {
  position: absolute;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f9fc;
}
.Login .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  margin-top: 30px;
  border-radius: 8px;
  min-height: 500px;
  padding: 30px 0px 30px 0px;
  width: 450px;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 0px 10px 5px rgba(200, 200, 200, 0.2);
}

.Login input {
  height: 50px;
  width: 280px;
  padding: 1rem;
  border: 2px solid #4659ff;
  font-weight: 500;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.Login input:focus {
  border: 2px solid #192de1;
}

.Login .auth-error-message {
  font-size: 13px;
  width: 80%;
  font-weight: 500;
  color: red;
  margin: 5px 0px 5px 0px;
  text-align: center;
}

.Login .card .login-button {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  height: 50px;
  width: 280px;
  color: #fff;
  font-weight: 600;
  background-color: #4659ff;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.Login .card .login-button:hover {
  background-color: #192de1;
}

.Login .card .button-link {
  background-color: transparent;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-weight: 500;
}
.Login .card .button-link:hover {
  color: #0285ff;
}

.Login .card .google-auth-button {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  height: 50px;
  width: 280px;
  color: #fff;
  font-weight: 600;
  background-color: #1890ff;
  border: none;
  cursor: pointer;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  border-radius: 0.3rem;
}

.Login .card .google-auth-button:hover {
  background-color: #0285ff;
}
